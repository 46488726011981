.dropdownList{
    position: relative;
}

.label{
    display: flex;
    align-items: center;
    padding: 5px;
    margin: 0 5px;
    cursor: pointer;
}

.label:hover  > *{

}

.label:hover .stateIndicator{

}

.label > *{
    padding: 0;
}

.dropdownList.collapsed .items{
    opacity: 0;
    pointer-events: none;
    transform: translateY(50px);
}

.items{
    position: absolute;
    top: 35px;
    left: 10px;
    box-sizing: border-box;
    width: 218px;
    padding: 24px 0;
    background: white;
    box-shadow: 0 8px 30px 0 var(--color-grey-darker);
    border-radius: 8px;
    transition: .3s all ease;
}

.items:focus{
    outline: none;
}

.stateIndicator{
    margin-left: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    border-color: white transparent transparent transparent;
    transition: .3s all ease;
}

.dropdownList.shown .stateIndicator{
    transform: rotate(180deg);
}


@media screen and (max-width: 1240px) {
    .label{
        /*padding: 20px;*/
    }
    .items{
        top: 65px;
        left: 20px;
    }
}

@media screen and (max-width: 940px) {
    .label{
        padding: 20px 5%;
        width: 100%;
        margin-left: 0;

        box-sizing: border-box;
    }
    .dropdownList{
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .items{
        padding: 14px 0;
        right: 20px;
        width: auto;
    }
    .stateIndicator{
        border-color: var(--color-red) transparent transparent transparent;
    }
}