.container{
    background: white;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 5%);
    border-radius: 10px;
    padding: 30px;
    margin: 30px 0;
    box-sizing: border-box;
}

@media screen and (max-width: 768px) {
    .container{
        padding: 15px;
    }
}