.linksContainer{
    display: flex;
    align-items: center;
}

.link{
    display: block;
    position: relative;
    color: white;
    font-size: 14px;
    text-decoration: none;
    padding: 5px;
    margin: 0 10px;
    transition: .2s transform ease;
}

.link:hover{
    transform: translateY(-2px);
}

@media screen and (max-width: 940px) {
    .linksContainer{
        display: block;
        width: 100%;
    }
    .linksContainer{
        padding: 0;
    }
    .link{
        font-size: 16px;
        color: var(--color-red);
        display: block;
        padding: 20px 5%;
        margin: 0;
        border-bottom: 1px solid var(--color-grey);
    }
    .link:last-child{
        border-bottom: none;
    }
}

@media (orientation: landscape) and (max-width: 940px) {
    .linksContainer{
        display: flex;
        flex-wrap: wrap;
    }
    .link{
        width: 30%;
        padding: 20px 10px;
        border-bottom: none;
    }
}