.wrapper{

}

.text{
    color: var(--color-darkblue);
    font-size: 28px;
    font-weight: 400;
    margin-right: 30px;
    margin-bottom: 30px;
}

@media screen and (max-width: 1240px) {
}

@media screen and (max-width: 768px) {
    .text{
        font-weight: 600;
        font-size: 22px;
        margin: 20px 10px;
        text-align: left;
    }
}