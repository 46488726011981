.form{
    position: relative;
}
.title{
    font-size: 18px;
    color: var(--color-darkblue);
    margin: 0;
}

.subtitle{
    font-size: 16px;
    color: var(--color-darkblue);
    margin-top: 30px;
    margin-bottom: 0;
}

/*.sampleContainer{*/
    /*border-bottom: 1px solid var(--color-grey);*/
    /*padding-bottom: 20px;*/
/*}*/

.sampleContainer .subtitle{
    margin-bottom: 20px;
    margin-top: 10px;
}

.comment{
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    color: var(--color-darkblue);
    font-weight: 300;
}

.buttonsContainer{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}


.buttonsContainer > *:first-child{
    margin-right: 20px;
}

.sampleFile{
    margin: 10px 0;
}

@media screen and (max-width: 768px) {
    .buttonsContainer{
        flex-direction: column-reverse;
        margin-top: 30px;
    }

    .buttonsContainer button{
        width: 100%;
        text-align: center;
        justify-content: center;
    }

    .buttonsContainer > *:first-child{
        margin-right: 0;
        margin-top: 15px;
    }

    .sampleContainer .subtitle{
        margin-top: 10px;
        text-align: center;
    }

    .sampleContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}