.wrapper{
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 1px;
    font-family: var(--default-font-family);
}

.logoWrapper{

}
.textWrapper b{
    font-weight: bold;
}
.logoWrapper svg{
    height: 100px;
}

@media screen {
    .wrapper{
        display: none;
    }
}
@media print {
    .wrapper{
        display: flex;
    }
}