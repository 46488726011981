.subHeader{
    font-size: 18px;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 0;
    color: var(--color-darkblue);
}

.header{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 24px;
    font-weight: 300;
    color: white;
    background: var(--color-grey-darker);
    margin: -30px -30px 30px -30px;
    padding: 30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 1px solid var(--color-grey);
    background: linear-gradient(270deg, var(--color-red) 0%, var(--color-darkred) 100%);
}

.headerStatus{
    display: inline-block;
    font-size: 11px;
    margin: 5px 0;
    padding: 2px 5px;
    color: white;
    border: 1px solid white;
    border-radius: 20px;
    margin-left: 20px;
}

.dealFiles{
    display: flex;
}

@media screen and (max-width: 768px) {
    .header{
        width: 100%;
        margin: -15px -15px 30px -15px;
        padding: 30px 15px;
        font-size: 20px;
    }
    .subHeader{
        margin-top: 20px;
    }
    .headerStatus:first-child{
        margin-left: 0;
    }
}