.wrapper{

}

.subHeader{
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
    color: var(--color-darkblue);
}

.activationDisclaimerAsterisk{
    color: var(--color-red);
}

.activationDisclaimer{
    font-weight: 300;
    color: var(--color-darkblue);
}

.colorGreen{
    color: var(--color-green);
}
.colorRed{
    color: var(--color-red);
}