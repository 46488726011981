.listWrapper{
    margin-top: calc(var(--navbar-height) + 30px);
    position: relative;
}

.headerWrapper{
    top: var(--navbar-height);
    position: sticky;
    z-index: 1;
}

.loaderContainer{
    color: var(--color-red);
    font-size: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.loadMoreButton{
    margin: auto;
    margin-top: 30px;
}

.allLoadedIndicator{
    opacity: 0;
    line-height: 0;
    font-size: 14px;
    color: var(--color-darkblue);
    width: 100%;
    text-align: center;
    bottom: 15px;
    transition: 5s all ease;
    animation: fadeOut 5s;
}

.emptyListText{
    text-align: center;
    width: 100%;
    color: var(--color-grey-darker);
    margin-top: 30px;
    font-weight: 300;
}

@media screen and (max-width: 1024px) {
    .headerWrapper{
        display: none;
    }
    .loadMoreButton{
        width: 100%;
        justify-content: center;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 20px;
    }
}

@keyframes fadeOut {
    0%{
        opacity: 1;
        margin: 30px 0;
    }
    90%{
        opacity: 1;
        margin: 30px 0;
    }
}