@font-face {
    font-family: 'Roboto';
    src: url(./assets/fonts/Roboto-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: url(./assets/fonts/Roboto-LightItalic.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url(./assets/fonts/Roboto-Regular.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url(./assets/fonts/Roboto-Italic.ttf) format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Roboto';
    src: url(./assets/fonts/Roboto-Bold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url(./assets/fonts/Roboto-BoldItalic.ttf) format('truetype');
    font-weight: 700;
    font-style: italic;
}

:root{
    --color-red: #ef3124;
    --color-darkred: #af2016;
    --color-grey: #f4f4f4;
    --color-grey-darker: #a8a8a8;
    --color-darkblue: #2c3e50;
    --color-green: #00b341;

    --navbar-height: 84px;
    --navbar-height-expanded: 126px;

    --container-border-radius: 20px;
    --default-font-family: 'Roboto', 'Helvetica Neue', sans-serif;
}

html{
    min-height: 100vh;
}

*::selection{
    background: var(--color-darkred);
    color: white;
}


button{
    font-family: var(--default-font-family);
    transition: .3s all ease;
}

button:active, a:active{
    transform: scale(.9);
}

a{
    text-decoration: none;
}

input,
textarea,
button,
select,
a {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body{
    background: #fcfcfc;
    margin: 0;
    font-family: var(--default-font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 768px) {
    *:focus{
        box-shadow: none;
    }
}

@media print {
    *:not(.printableArea *) {
        visibility: hidden;
        margin:0!important;
        padding:0!important;
        position: absolute;
        background: transparent;
    }

    .printableArea, .printableArea * {
        position: static;
        visibility: visible;
        background: white;
    }
}
