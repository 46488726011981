.header{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 24px;
    font-weight: 300;
    color: white;
    background: var(--color-grey-darker);
    margin: -30px -30px 30px -30px;
    padding: 30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 1px solid var(--color-grey);
    background: linear-gradient(270deg, var(--color-red) 0%, var(--color-darkred) 100%);
}

.printButtonWrapper{
    display: flex;
    justify-content: flex-end;
}

@media screen and (max-width: 768px) {
    .header{
        width: 100%;
        margin: -15px -15px 30px -15px;
        padding: 30px 15px;
        font-size: 20px;
    }
    .printButtonWrapper{
        display: block;
        justify-content: flex-start;
    }
    .printButton{
        display: block;
        width: 100%!important;
    }
}

