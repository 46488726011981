.wrapper{
    position: relative;
}
.scheduleHeader{
    font-size: 18px;
    font-weight: bold;
    color: var(--color-darkblue);
}
.dataRow{
    display: flex;
    color: var(--color-darkblue);
    font-size: 14px;
    margin-bottom: 5px;
}

.dataRow .label{
    width: 400px;
    font-weight: bold;
    margin-right: 10px;
}
.dataRow .value{
    width: 120px;
    text-align: right;
}

.errorMessage{
    color: var(--color-red);
    font-size: 16px;
    text-align: center;
}
.printedContactInfo{
    display: none;
}
.disclaimer{
    font-size: 11px;
    margin-top: 20px;
    color: var(--color-grey-darker);
    font-weight: 300;
}
@media screen and (max-width: 768px) {
    .dataRow .label{
        width: 60%;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .dataRow .value{
        width: auto;
        text-align: left;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .disclaimer{
        margin-bottom: 10px;
    }
}

@media print {
    .wrapper *{
        box-shadow: none;
    }
    .wrapper{
        width: 100vw;
        box-sizing: border-box;
    }
    .wb{
        padding: 10px;
        margin-top: 0;
        box-sizing: border-box;
    }
    .printedContactInfo{
        display: block;
        margin-top: 20px;
        font-size: 14px;
    }
    .printedContactInfo > *{
        margin-right: 5px;
    }

    .printedContactInfo b{
        font-weight: bold;
    }
    .scheduleHeader{
        text-align: center;
        color: black;
        margin-bottom: 30px;
    }
    .commonDataRows{

    }
    .disclaimer{
        margin-bottom: 30px;
        color: black;
        font-size: 14px;
        font-weight: 400;
        text-align: justify;
        text-justify: inter-word;
        width: 100%;
    }
    .dataRow{
        color: black;
        margin-bottom: 5px;
    }
}