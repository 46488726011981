.wrapper{
    position: relative;
}

.headerWrapper{
    top: var(--navbar-height);
    position: sticky;
    z-index: 1;
}

@media screen and (max-width: 768px) {
    .headerWrapper{
        display: none;
    }
}

@media print {
    .headerWrapper{
        position: static;
    }
}
