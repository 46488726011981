.wrapper{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container{

}

.header{
    font-size: 24px;
    color: var(--color-darkblue);
    font-weight: 600;
    margin-top: 0;
}

.errorMessage{
    color: var(--color-red);
    margin-bottom: 20px;
    font-weight: 300;
}

.confirmed{
    color: var(--color-darkblue);
    margin-bottom: 20px;
    font-weight: 300;
}
