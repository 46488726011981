.wrapper{
    background: white;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;
    width: 1200px;
    max-width: 100%;
    box-shadow: 0 0 14px 0 rgb(0 0 0 / 10%);

    border-radius: 20px;
}
.topRightImageWrapper{
    position: absolute;
    right: -230px;
    top: -230px;
}
.bottomLeftImageWrapper{
    position: absolute;
    left: -140px;
    bottom: -60px;
    transform: rotateZ(30deg) scale(1.4);
}
.bottomRightImageWrapper{
    position: absolute;
    right: -20px;
    bottom: -20px;
    transform: rotate(180deg);
}
.topLeftImageWrapper{
    position: absolute;
    left: 50px;
    top: 40px;
}

.largeSingleCircle{
    width: 400px;
    height: 400px;
    border-radius: 100%;
    background: var(--color-red);
}

.circlePairWrapper{
    position: relative;
}

.largePairedCircle{
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background: var(--color-red);
}

.smallPairedCircle{
    position: absolute;
    bottom: -30px;
    right: -60px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: var(--color-red);
}
.logoWrapper svg{
    transform: scale(1.5);
}
.logoWrapper svg path{
    fill: var(--color-red);
}

@media screen and (max-width: 1200px) {
    .wrapper{
        width: 90%;
        margin: auto;
    }
}

@media screen and (max-width: 768px) {
    .topRightImageWrapper{
        position: absolute;
        right: -90px;
        top: -120px;
    }
    .bottomLeftImageWrapper{
        position: absolute;
        left: -170px;
        bottom: -100px;
        transform: rotateZ(10deg) scale(.6);
    }
    .bottomRightImageWrapper{
        position: absolute;
        right: -10px;
        bottom: -10px;
        transform: rotate(180deg);
    }
    .topLeftImageWrapper{
        position: absolute;
        left: 40px;
        top: 40px;
    }
    .largePairedCircle{
        width: 40px;
        height: 40px;
    }

    .smallPairedCircle{
        bottom: -10px;
        right: -20px;
        width: 10px;
        height: 10px;
    }
    .largeSingleCircle{
        width: 200px;
        height: 200px;
    }
}