.wrapper{
    display: flex;
    flex-direction: column;
}

.image{
    width: 100%;
}

.text{
    margin-top: 20px;
    color: var(--color-red);
    font-size: 36px;
    font-weight: bold;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .text{
        font-size: 24px;
        text-align: center;
    }
}