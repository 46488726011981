.wrapper{
    position: relative;
    display: flex;
    width: fit-content;
    flex-direction: column;
    align-items: center;
    flex-grow: 0;
    cursor: pointer;
    transition: .3s all ease;
}

.wrapper svg{
    height: 64px;
}

.wrapper svg path{
    fill: var(--color-darkblue);
    transition: .3s all ease;
}

.name{
    color: var(--color-darkblue);
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
    transition: .3s all ease;
    max-width: 180px;
    font-weight: bold;
}

.ext{
    top: 26px;
    text-align: center;
    width: 100%;
    position: absolute;
    color: var(--color-darkblue);
    font-weight: 400;
    font-size: 12px;
    transition: .3s all ease;
}

.wrapper:hover .name, .wrapper:hover .ext{
    color: var(--color-red);
}

.wrapper:hover svg path{
    fill: var(--color-red);
}