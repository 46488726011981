.wrapper{
    display: flex;
    background: white;
    color: var(--color-darkblue);
    border-radius: 10px;
    font-size: 14px;
    position: relative;
}

.wrapper:active{
    transform: none;
}

.wrapper:hover .cell{
    color: var(--color-red);
}

.wrapper:nth-child(odd){
    background: #fcfcfc;
}

.label{
    font-weight: bold;
    margin-right: 10px  ;
    display: none;
}

.label:after{
    content: '';
}


.cell:last-child .label:after{
    content: ':';
}

.value{
    text-overflow: ellipsis;
    overflow: hidden;
}

.cell{
    padding: 10px;
    box-sizing: border-box;
    flex-basis: 100%;
    display: flex;
    align-items: center;
    min-width: 0;
}

.tagsContainer{
    position: absolute;
    bottom: 5px;
}

.tag{
    display: inline-block;
    font-size: 9px;
    margin: 5px 0;
    padding: 2px 5px;
    line-height: 10px;
    background: var(--color-grey);
    color: var(--color-grey-darker);
    border-radius: 20px;
    margin-left: 20px;
}

@media screen and (max-width: 1280px) {
    .cell{
        padding: 10px;
    }
    .tag:first-child{
        margin-left: 10px;
    }
}

@media screen and (max-width: 768px) {
    .wrapper{
        display: block;
        padding: 10px 0;
        font-size: 14px;
        border-bottom: 1px solid var(--color-grey);
        border-radius: 0;
    }

    .cell{
        align-items: flex-start;
        padding: 2px 5px;
    }

    .label{
        width: 60%;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }

    .wrapper:hover *{
        color: var(--color-darkblue);
    }
    .tag:first-child{
        margin-left: 0;
    }


    .wrapper:nth-child(odd){
        background: initial;
    }
    .wrapper:last-child{
        border-bottom: none;
    }
    .label{
        display: block;
    }
}

@media print {
    .wrapper{
        color: black;
        font-size: 14px;
    }
    .wrapper:hover .cell{
        color: black;
    }
    .cell{
        outline: 1px solid black;
    }
}