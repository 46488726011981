.wrapper{
    display: flex;
    align-items: center;
}

.fileIconWrapper svg{
    height: 20px;
    display: flex;
}

.fileIconWrapper svg path{
    fill: var(--color-darkblue);
}

.name{
    color: var(--color-darkblue);
    font-size: 16px;
    margin-left: 5px;
    max-width: 50%;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.size{
    color: var(--color-grey-darker);
    font-size: 16px;
    margin-left: 10px;
    font-weight: 300;
}
.removeButton{
    border:none;
    background: transparent;
    font-size: 14px;
    font-family: var(--default-font-family);
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px;
}

.removeButton svg{
    height: 16px;
    margin-right: 5px;
}

.removeButton svg path{
    fill: var(--color-darkred);
    transition: .3s all ease;

}
.removeButton:hover svg path{
    fill: var(--color-red);
}

.removeButton .buttonText{
    color: var(--color-darkred);
    font-weight: 300;
    transition: .3s all ease;
    line-height: 0;
}
.removeButton:hover .buttonText{
    color: var(--color-red);
}

@media screen and (max-width: 768px) {
    .removeButton .buttonText{
        display: none;
    }
}