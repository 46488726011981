.button{
    font-size: 16px;
    padding: 20px 40px;
    border-radius: 6px;
    display: flex;
    align-items: center;
}

.button.filled{
    border: none;
    color: white;
    background: var(--color-red);
}

.button.outlined{
    border: 1px solid var(--color-red);
    color: var(--color-red);
    background: transparent;
}

.button svg{
    margin-left: 10px;
    transition: .3s transform ease;
}

.button.reversed svg{
    margin-left: 0;
    margin-right: 10px;
    transform: rotateZ(180deg);
}

.button.outlined svg path, .button.outlined svg line{
    stroke: var(--color-red);
}

.button.filled svg path, .button.filled svg line{
    stroke: white;
}

.button:hover svg{
    transform: translateX(3px);
}

.button.reversed:hover svg{
    transform: rotateZ(180deg) translateX(3px);
}

.button:disabled:hover svg{
    transform: none;
}

.button.filled:disabled{
    background: var(--color-grey-darker);
    cursor: not-allowed;
}

.button.outlined:disabled{
    border-color: var(--color-grey-darker);
    color: var(--color-grey-darker);
    cursor: not-allowed;
}