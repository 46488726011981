.wrapper{
    position: relative;
}
.title{
    font-size: 18px;
    color: var(--color-darkblue);
    margin: 0;
}
.text{
    font-size: 18px;
    font-weight: 300;
    color: var(--color-darkblue);
    margin-top: 10px;
    margin-bottom: 20px;
}

.confirmationButtonsContainer{
    display: flex;
}

.confirmationButtonsContainer > button:first-child{
    margin-right: 20px;
}

@media screen and (max-width: 768px) {
    .confirmationButtonsContainer{
        flex-direction: column-reverse;
    }
    .confirmationButtonsContainer > button:first-child{
        margin-right: 0;
        margin-top: 20px;
    }
    .wrapper button{
        width: 100%;
        justify-content: center;
    }
}