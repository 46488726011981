.tab{
    color: var(--color-darkblue);
    font-size: 16px;
    padding: 15px 25px;
    margin: 0 10px;
    border: 1px solid var(--color-grey-darker);
    background: white;
    border-radius: 10px;
    transition: .2s all ease;
    display: block;
}

.tab.active{
    background: var(--color-red);
    color: white;
    border-color: var(--color-red);
}

.tab.vertical{
    display: block;
    width: 100%;
    margin: 10px 0;
}