.dropArea{
    display: inline-block;
    padding: 40px;
    border: 1px dashed var(--color-darkblue);
    color:  var(--color-darkblue);
    border-radius: 10px;
    transition: .3s all ease;
    margin: 0;
    text-align: center;
}

.dropArea.active{
    border-color: var(--color-red);
    color: var(--color-red);
}

.dropArea{
    cursor: pointer;

}

.filesContainer{

}

.filesContainer > *{
    margin-bottom: 10px;
}
.allowedExtList{
    color: var(--color-grey-darker);
}
@media screen and (max-width: 768px) {
    .dropArea{
        width: 100%;
        box-sizing: border-box;
        padding: 20px 10px;
    }
}