.wrapper{
    width: 400px;
    max-width: 100%;
    padding: 40px 20px;
    margin-top: 10px;
    position: relative;
}
.formTitle{
    text-align: center;
    font-weight: 400;
    font-size: 28px;
    color: var(--color-darkblue);
    margin-bottom: 10px;
}
.formSubtitle{
    text-align: center;
    color: var(--color-grey-darker);
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 30px;
    font-weight: 400;
}
.fieldCategoryTitle{
    margin-top: 30px;
    margin-bottom: -10px;
    font-weight: 400;
    color: var(--color-darkblue)
}
.buttonsContainer{
    margin-top: 30px;
    display: flex;
}

.buttonsContainer button:first-child{
    margin-right: 20px;
}

.errorContainer{
    margin-top: 20px;
    margin-bottom: -10px;
    color: var(--color-red)
}