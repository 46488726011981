.linksContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}
.homePageLink{
    text-decoration: none;
    margin: 20px 30px;
    padding: 40px 20px;
    font-size: 26px;
    font-weight: 300;
    color: var(--color-red);
    border: 1px solid var(--color-red);
    border-radius: 10px;
    box-sizing: border-box;
    text-align: center;
    transition: .3s all ease;
    flex-basis: 100%;
}
.homePageLink:last-child, .homePageLink:first-child{
    margin: 0;
}
.notActivated{
    color: var(--color-darkblue);
    font-size: 19px;
}
.notActivated h2{
    font-size: 20px;
}
.notActivated p{
    font-weight: 300;
}
@media screen and (max-width: 768px) {
    .linksContainer{
        min-height: auto;
        flex-direction: column;
        margin-top: 20px;
    }
    .homePageLink{
        margin: 20px 10px;
        width: 100%;
    }
}