.appHeaderWrapper{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
}

.appHeader{
    background: var(--color-red);
    padding: 0 calc((100vw - 1200px)/2);
    height: var(--navbar-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logoImage{
    max-width: 100px;
    margin-left: 30px;
    margin-right: 10px;
}

.phoneIconImage{
    max-width: 60px;
    margin: 0 15px;
}

.interactiveItemsWrapper{
    display: flex;
    align-items: center;
}

.logoLink{
    display: flex;
    align-items: center;
    text-decoration: none;
}

@media screen and (max-width: 1240px){
    .appHeader {
        padding: 0 5%;
    }
}

@media screen and (max-width: 940px){
    .logoImage{
        margin-left: 0;
    }
    .appHeaderWrapper{

    }

    .appHeaderWrapper.mobileExpanded{
       bottom: 0;
    }

    .appHeaderWrapper .appHeader{
        padding: 0 5%;
        transition: .2s all ease;
    }
    .appHeaderWrapper.mobileExpanded .appHeader{
        height: var(--navbar-height-expanded);
        background: white;
    }

    .appHeaderWrapper.mobileExpanded .interactiveItemsWrapper{
        opacity: 1;
        pointer-events: all;
        transform: translateX(0);
        transition: .2s opacity linear, .3s transform ease;
    }

    .appHeaderWrapper .interactiveItemsWrapper{
        transform: translateX(120px);
        flex-direction: column-reverse;
        justify-content: flex-end;
        align-items: flex-start;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: calc(var(--navbar-height-expanded) - 1px);
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
        transition: .1s opacity linear, .3s transform ease;
    }

    .appHeaderWrapper.mobileExpanded svg path{
        fill: var(--color-red);
    }

    .appHeaderWrapper.mobileExpanded .contactButtonWrapper{
        transform: none;
    }

    .appHeaderWrapper .contactButtonWrapper{
        margin-top: 40px;
        transform: translate(-120px, 120px);
        width: 100%;
        transition: .3s all ease;
    }

}