.pageWrapper{
    /* overflow: hidden; Fix for the AOS issue that causes horizontal scrollbar to appear on mobile */
    width: 1200px;
    max-width: 100%;
    margin: auto;
    margin-top: calc(var(--navbar-height) + 30px);
    padding: 0 30px;
    min-height: calc(100vh - var(--navbar-height ) - 30px);
    box-sizing: border-box;
    padding-bottom: 60px;
}

@media screen and (max-width: 1240px){
    .pageWrapper{
        margin: 0 5%;
        width: auto;
        margin-top: calc(var(--navbar-height) + 30px);
    }
}

@media screen and (max-width: 768px){
    .pageWrapper{
        padding: 0 10px;
        padding-bottom: 60px;
        margin: 0;
        margin-top: calc(var(--navbar-height) + 30px);
        width: 100%;
    }
}