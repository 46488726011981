.separator{
    display: flex;
    align-items: center;
}

.line{
    flex-grow: 1;
    border-bottom: 1px solid var(--color-grey);
}

.separator.single .line:first-child{
    display: none;
}

@media screen and (max-width: 768px) {
    .line{
        display: none;
    }
    .separator{
        display: block;
        margin: 0;
    }

    .separator.single{
        margin: 0;
        flex-direction: column-reverse;
    }
    .separator.single .line{
        width: 100%;
    }
}