.hidden{
    opacity: 0;
    pointer-events: none;
    position: absolute;
}
.wrapperAbsolute{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(2px);
    background: rgba(255, 255, 255, 0.5);
}

.wrapperInline{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.label{
    margin-top: 10px;
    color: var(--color-red);
    font-size: 10px;
    text-align: center;
}

svg.loaderImage path{
    transition: .3s all ease;
    stroke: var(--color-red);
    stroke-linejoin: round;
    stroke-linecap: round;
    animation: strokeAnimation 3s infinite linear both;
    stroke-dasharray: 30px;
    stroke-dashoffset: 0;
}
/*svg.loaderImage path{*/
    /*fill: red;*/
/*}*/
@keyframes strokeAnimation {
    0%{
        stroke-dashoffset: 0;
    }
    50%{
        stroke-dashoffset: 100%;
        fill: var(--color-red);
        stroke: transparent;

    }
    100%{
        stroke-dashoffset: 200%;
    }
}