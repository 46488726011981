.wrapper{
    display: flex;
    align-items: center;
    margin-right: 30px;
}

.dropdown > div{
    margin-right: 0;
    padding-right: 0;
}

.label{
    color: white;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.label svg{
    margin-right: 5px;
    width: 15px;
}

.label svg path{
    fill: white;
}

.link{
    cursor: pointer;
    display: block;
    padding: 12px 30px;
    color: var(--color-darkblue);
    font-size: 16px;
    font-weight: 500;
    transition: .3s all ease;
    text-decoration: none;
}

.link:hover{
    color: var(--color-red);
}

.spacer{
    border-right: 1px solid var(--color-darkred);
    height: 34px;
    margin: 0 15px;
}

@media screen and (max-width: 940px) {
    .spacer{
        display: none;
    }
    .label{
        color: var(--color-red);
        font-size: 16px;
    }
    .wrapper{
        width: 100%;
    }
    .label svg{
        margin-right: 12px;
        width: 25px;
    }
    .link{
        padding: 20px;
    }
}
