.tab{
    color: var(--color-red);
    font-size: 16px;
    padding: 10px 25px;
    border: 1px solid var(--color-red);
    border-left: none;
    background: white;
    display: flex;
    justify-content: center;
    transition: none;
    box-sizing: border-box;
}

.tab:first-child{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-left: 1px solid var(--color-red);
}

.tab:last-child{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.tab.active{
    background: var(--color-red);
    color: white;
    border-color: var(--color-red);
}

.tab:active{
    transform: none;
}

.label{
    transition: .1s all ease;
}

.tab:active .label{
    transform: scale(.7);
}

@media screen and (max-width: 768px) {
    .tab{
        padding: 10px;
        flex: 1;
    }
    .label{
        overflow-x: hidden;
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap;
    }
}