.wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 80vh;
    padding: 10px;
}

.statusCode{
    font-size: 88px;
    color: var(--color-red);
    margin-bottom: 0;
}

.statusText{
    color: var(--color-darkblue);
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 20px;
    font-weight: 300;
}
.decoration{
    font-weight: normal;
    color: var(--color-grey-darker);
}