.calculatorForm{
    display: block;
    font-size: 14px;
    position: relative;
}
.calculatorForm label{
    display: block;
    width: 100%;
    margin-bottom: 10px;
}
.calculatorForm label{
    display: block;
    width: 100%;
    margin-bottom: 10px;
}

.calculatorForm label *{
    display: block;
    min-width: 200px;
}

.subHeader{
    color: var(--color-darkblue);

    display: inline-block;
    font-size: 16px;
    margin-bottom: 20px;
}

.tabInput{
    justify-content: flex-start;
}

.tabInput > *{
    flex-basis: 100%;
}

.rangeLabel{
    color: var(--color-darkblue);
    font-weight: 300;
}

.regularInput{
    margin-bottom: 0;
    margin-top: 0;
}

.inputsRow{
    display: flex;
    margin-bottom: 20px;
}

.inputContainer{
    width: 50%;
}

.inputContainer:first-child{
    padding-right: 20px;
}
.errorMessage{
    color: var(--color-red);
    font-size: 12px;
    margin: 20px 0;
}
.constraintsDisclaimerText{
    font-size: 12px;
    color: var(--color-grey-darker);
    margin-top: 25px;
}

.constraintsDisclaimerText .dealerName{
    margin-left: 5px;
}

.constraintsLink{
    margin-right: 5px;
    position: relative;
    padding-top: 10px;
    padding-bottom: 1px;
    cursor: pointer;
    border-bottom: 1px dashed var(--color-grey-darker);
    transition: .3s all ease;
}
.constraintsLink:hover{
    color: var(--color-darkblue);
    border-color: var(--color-darkblue);
}
.constraintsLink:hover .constraintsList{
    opacity: 1;
    transform: none;
    pointer-events: initial;
}

.constraintsList{
    opacity: 0;
    transform: translateY(-100px);
    transition: .3s all ease;
    position: absolute;
    background: white;
    padding: 20px;
    left: 0;
    bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 8px 30px 0 var(--color-grey-darker);
    pointer-events: none;
    cursor: initial;
}

.constraintsListItem{
    color: var(--color-darkblue);
    padding: 5px 0;
    font-size: 14px;
}

.constraintsListItem.selected {
    color: var(--color-red);
}

.constraintTitle{
    font-weight: bold;
}

.constraintValues{
    display: flex;
    flex-wrap: nowrap;
}

.constraintValueItem{
    margin-right: 10px;
    white-space: nowrap
}

@media screen and (max-width: 1240px) {

}

@media screen and (max-width: 1024px) {
    .inputsRow{
        display: block;
        margin-bottom: 0;
    }

    .subHeader{
        margin-top: 30px;
    }

    .inputContainer{
        width: 100%;
    }

    .inputContainer:first-child{
        padding-right: 0;
    }

    .regularInput{
        max-width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .constraintsDisclaimerText{
        font-size: 14px;
    }
    .constraintsDisclaimerText > *{
        line-height: 25px;
    }

    .constraintsListItem{
        line-height: normal;
        font-size: 12px;
    }

    .errorMessage{
        font-size: 14px;
    }
}