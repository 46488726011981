.buttonsWrapper{

}

.buttonsWrapper > *:first-child{
    margin-bottom: 20px;
}
.buttonsWrapper button{
    width: 100%;
    justify-content: center;
}

.text{
    color: #2c3e50;
    font-weight: 300;
    font-size: 18px;
    text-align: center;
    margin-bottom: 40px;
}

.container{
    width: 40%;
    margin: auto;
    margin-top: 10%;
    position: relative;
}

.wolfImageWrapper{
    position: absolute;
    left: 100%;
    bottom: 0;
    display: flex;
}

.wolfImageWrapper svg{
    width: 200px;
    height: auto;
}

.wolfImageWrapper svg path{
    fill: var(--color-grey);
}

.logoutImageWrapper{
    margin: 40px 0;
    display: flex;
    justify-content: center;
}
.logoutImageWrapper svg{
    width: auto;
    height: 220px;
}
.logoutImageWrapper svg path{
    fill: var(--color-grey-darker);
}
.logoutImageWrapper svg path:nth-child(1){
    fill: var(--color-red);
}

@media screen and (max-width: 1024px) {
    .container{
        width: 50%;
    }
}

@media screen and (max-width: 768px) {
    .container{
        width: 100%;
        margin-top: 0;
    }
    .wolfImageWrapper{
        display: none;
    }
    .logoutImageWrapper{
        margin: 20px 0;
    }
    .logoutImageWrapper svg{
        height: 60px;
    }
}