.wrapper{

    color: var(--color-darkblue);
}

.payment{
    font-size: 32px;
    font-weight: 300;
}

.behavior{
    font-size: 12px;
    font-weight: 400;
}
.instructions{

}