.wrapper{
    display: flex;
    background: white;
    cursor: pointer;
    transition: .3s all ease;
    color: var(--color-darkblue);
    font-weight: bold;
}

.cell{
    font-size: 14px;
    padding: 30px 20px;
    box-sizing: border-box;
    flex-basis: 100%;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 0;
}

@media screen and (max-width: 1280px) {
    .cell{
        padding: 20px 10px;
    }
}
