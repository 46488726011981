.row{
    font-size: 18px;
    color: var(--color-darkblue);
    display: flex;
    margin: 20px 0;
}

.row.empty{
    color: var(--color-grey-darker);
}

.label{
    font-weight: 400;
    margin-right: 10px;
    min-width: 200px;
}

.value{
    font-weight: 300;
}

.value a{
    color: var(--color-darkblue);
    text-decoration: none;
    border-bottom: 1px dotted var(--color-darkblue);
}

@media(max-width: 768px) {
    .row{
        display: block;
        margin: 10px 0;
    }
    .label{
        min-width: auto;
    }
}