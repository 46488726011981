.wrapper{
}

.row{
    display: flex;
    font-size: 18px;
    color: var(--color-darkblue);
    margin-bottom: 10px;
}

.rowLabel{
    min-width: 160px;
    margin-right: 10px;
}

.rowValue{
    font-weight: 300;
}

.titleContainer{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.titleText{
    font-size: 24px;
    color: var(--color-darkblue);
    margin-left: 10px;
}

.titleContainer svg{
    width: 30px;
    height: auto;
}

.titleContainer svg path{
    stroke: var(--color-green);
}

.buttonsContainer{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}


.buttonsContainer > *:first-child{
    margin-right: 20px;
}

@media screen and (max-width: 960px) {
    .row{
        display: block;
    }

    .rowLabel{
        min-width: 0;
    }

}

@media screen and (max-width: 768px) {
    .buttonsContainer{
        display: block;
    }

    .buttonsContainer button{
        width: 100%;
        text-align: center;
        justify-content: center;
    }

    .buttonsContainer > *:first-child{
        margin-right: 0;
        margin-bottom: 15px;
    }
}