.sessionWrapper{
    margin: 20px 0;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    position: relative;
    background: white;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 5%);
}
.sessionWrapper.current{
    border-color: var(--color-green);
}
.sessionWrapper.current .currentSessionText{
    display: flex;
}
.sessionWrapper.current .deviceInfo{
    background: linear-gradient(280deg, var(--color-darkblue) 0%, var(--color-red) 100%);
}
.deviceInfo{
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-right: 1px solid var(--color-grey);
    width: 180px;
    background: var(--color-grey-darker);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.deviceName {
    width: 100%;
    text-align: left;
    font-size: 14px;
    color: white;
}

.osName {
    width: 100%;
    text-align: left;
    font-size: 14px;
    font-weight: 300;
    color: white;
}

.infoContainer{
    padding: 20px;
    color: var(--color-darkblue);
    font-size: 16px;
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.infoContainer > *:first-child{
    margin-right: 20px;
}

.infoRow{
    display: flex;
}
.infoLabel{
    margin-right: 10px;
    font-weight: 400;
}
.infoValue{
    font-weight: 300;
}
.iconWrapper{
    display: flex;
    margin-right: 10px;
}
.iconWrapper svg{
    height: 32px;
    width: auto;
}

.iconWrapper svg path, .iconWrapper svg circle{
    fill: white;
}

.currentSessionText{
    display: none;
    align-items: center;
    color: green;
    font-weight: 300;
    margin: 20px;
    font-size: 14px;
}

.sessionWrapper .destroyButton{
    margin: 20px;
    padding: 10px 20px;
    box-sizing: border-box;
}

@media(max-width: 1024px){
    .infoContainer{
        display: block;
    }
}

@media(max-width: 768px){
    .infoContainer > *:first-child{
        margin-right: 0;
    }
    .currentSessionText{
        width: 100%;
        text-align: center;
        padding-bottom: 25px;
        font-size: 16px;
        margin: 0;
        font-weight: 400;
        justify-content: center;
    }
    .sessionWrapper{
        display: block;
        min-height: 0;
    }
    .deviceInfo{
        border-bottom-left-radius: 0;
        border-top-right-radius: 10px;
        width: 100%;
        box-sizing: border-box;
    }
    .sessionWrapper .destroyButton{
        position: static;
        width: 100%;
        padding: 20px;
        margin: 0;
        justify-content: center;
    }
}