.form{
    position: relative;
}

.inputRow{
    display: flex;
    flex-wrap: wrap;
}

.inputRow > *{
    margin-right: 20px;
}
.subHeader{
    color: var(--color-darkblue);
    margin-bottom: 5px;
    display: inline-block;
}

.form input{
    width: 300px;
    max-width: 100%;
}

.buttonContainer{
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
}

.buttonContainer > button:first-child{
    margin-right: 20px;
}

.scheduleAttachmentDisclaimer{
    color: var(--color-darkblue);
    font-size: 12px;
    text-align: right;
    width: 100%;
    margin-top: 20px;
}

.errorContainer{
    margin-top: 20px;
    margin-bottom: -10px;
    color: var(--color-red)
}

@media screen and (max-width: 768px) {
    .currencyInput{
        margin-top: 20px;
    }
    .inputRow{
        display: block;
    }
    .inputRow > *{
        margin-right: 0;
    }
    .form input{
        width: 100%;
    }
    .buttonContainer{
        display: block;
        margin-top: 30px;
    }
    .buttonContainer button{
        width: 100%;
        justify-content: center;
    }
    .buttonContainer > button:first-child{
        margin-right: 0;
        margin-bottom: 20px;
    }
}